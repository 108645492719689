import React, { useCallback, useEffect, useRef, useState } from "react";
import ProductDetailsImage from "../../../components/sections/AdminSection/ProductDetailsImage";
import ProductDetailsText from "../../../components/sections/ProductDetailsText";
import ProductDetailsTab from "../../../components/sections/AdminSection/ProductDetailsTab";
import { useParams } from "react-router-dom";
import { productViewDetails } from "../../../services/productService";
import { getReviewList } from "../../../services/reviewService";
import PageLoader from "../../../components/common/PageLoader";

const AdminProductDetails = () => {
  const { id } = useParams();
  const [detailsData, setDetailsData] = useState({});
  const [reviewList, setReviewList] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const cardRef = useRef(null);

  const getDetails = useCallback(() => {
    setDetailsData({ loading: true, data: {} });
    productViewDetails(id).then((res) => {
      if (res?.status === 200) {
        setDetailsData({
          loading: true,
          data: res?.docs,
        });
      }
      setDetailsData({ loading: false, data: res?.docs });
    });
  }, []);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const getRiviewDetails = useCallback(() => {
    setReviewList({ loading: true, data: [] });
    getReviewList({ product_id: id, perPage: 9999, is_approved: 1 }).then((res) => {
      if (res?.docs?.status === 200) {
        setReviewList({
          loading: true,
          data: res?.docs?.data,
        });
      }
      setReviewList({ loading: false, data: res?.docs?.data });
    });
  }, [id]);

  useEffect(() => {
    getRiviewDetails();
  }, [getRiviewDetails]);

  const scrollHandler = (type) => {
    if (cardRef.current) {
      const scrollElement = document.querySelector("body");
      switch (type) {
        case "review":
          if (scrollElement) {
            cardRef.current.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });
          }
          setActiveTab(1);
          break;
        case "short_description":
          if (scrollElement) {
            cardRef.current.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });
          }
          setActiveTab(0);
          break;
        case "gallery":
          if (scrollElement) {
            cardRef.current.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });
          }
          setActiveTab(2);
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      {detailsData?.loading ? (
        <PageLoader />
      ) : (
        <div className="sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto pb-20">
          {/* <div className="md:pt-5 pt-2 md:pb-10 pb-5"><DetailsBreadcrumb  items={items}/></div> */}

          <div className="flex md:mt-20 mt-10 w-full justify-center">
            <div className="3xl:w-9/12 md:w-10/12 w-full md:flex block bg-white lg:p-8 md:p-8 p-6 rounded-lg justify-center items-center shadow-xl">
              <div className="lg:w-4/12 md:w-1/2 w-full">
                <ProductDetailsImage
                  data={detailsData?.data}
                  scrollHandler={scrollHandler}
                />
              </div>
              <div className="lg:w-7/12 w-full md:pl-14 pl-0">
                <ProductDetailsText
                  data={detailsData?.data}
                  reviewList={reviewList}
                  scrollHandler={scrollHandler}
                />
              </div>
            </div>
          </div>

          <div ref={cardRef}>
            <ProductDetailsTab
              data={detailsData?.data}
              reviewList={reviewList}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AdminProductDetails;
