import React from "react";
import Image2 from "../../../assets/images/cards/card-inside-3.webp";
import logo from "../../../assets/images/logo.png";
import Image from "../../elements/Image";

const ProductDetailsImage = ({ data, scrollHandler = () => {} }) => {
  return (
    <>
      <div className="w-full overflow-hidden " onClick={() => scrollHandler("gallery")}>
        <Image
          src={
            data?.productImages?.find((item) => item?.default_status === 1)
              ? data?.productImages?.find((item) => item?.default_status === 1)?.imageUrl
              : logo
          }
          alt=""
          effect="blur"
          className="w-full h-full object-cover"
        />
      </div>
    </>
  );
};

export default ProductDetailsImage;
